import React, { useRef } from 'react'
import Constraint from "../../layout/wrapper"
import WideWrapper from "../../layout/wide-wrapper"

import Header from "./components/header"
import Intro from "./components/intro"
import Section2 from "./components/section2"
import Section3 from "./components/section3"
import Section4 from "./components/section4"
import Section5 from "./components/section5"
import Section6 from "./components/section6"
import Section7 from "./components/section7"
import Section8 from "./components/section8"
import Section9 from "./components/section9"
import Footer from "./components/footer"


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

export default function Home() {
  const aboutRef = useRef(null)
  const featureRef = useRef(null)
  const contactRef = useRef(null)
  const executeScroll = (_ref)=>{
    if(_ref == 'about')
      scrollToRef(aboutRef)
    if(_ref == 'feature')
      scrollToRef(featureRef)
    if(_ref == 'contact')
      scrollToRef(contactRef)
  }

  return <div>

        <Constraint 
          child={
            <Header
              onCLick={executeScroll}
            />
            }
          classNames=" flex mb-4"
        />
        <Constraint 
          child={
            <Intro onCLick={executeScroll} />
            }
          classNames=" flex mb-4"
        />
        <WideWrapper
          child={
            <Section2/>
            }
          classNames=" flex mb-4"
          _Ref={aboutRef}
        />
        <Constraint 
          child={
            <Section3/>
            }
          classNames=" flex mb-4"
        />
         {/* <Constraint 
          child={
            <Section4/>
            }
          classNames=" flex mb-4"
        /> */}
         <Constraint 
          child={
            <Section5/>
            }
          classNames=" flex mb-0"
          _Ref={featureRef}
        />
         <Constraint 
          child={
            <Section6/>
            }
          classNames="flex mb-0 bg-accent"
        />
         <Constraint 
          child={
            <Section7/>
            }
          classNames="flex mb-0"
        />
        <Section8/>
        <Constraint 
          child={
            <Section9/>
            }
          classNames="flex mb-0 bg-accent"
          styles={{
            backgroundImage:`url(${require("../../assets/images/img-footer-bg.jpg")})`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom'
          }}
          _Ref={contactRef}
        />
        <Constraint 
          child={
            <Footer/>
            }
          classNames=" flex mb-4"
        />







      {/* <div className="flex mb-4">
        <div className="w-1/2 bg-gray-400 h-12"></div>
        <div className="w-1/2 bg-gray-500 h-12"></div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/3 bg-gray-400 h-12"></div>
        <div className="w-1/3 bg-gray-500 h-12"></div>
        <div className="w-1/3 bg-gray-400 h-12"></div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/4 bg-gray-500 h-12"></div>
        <div className="w-1/4 bg-gray-400 h-12"></div>
        <div className="w-1/4 bg-gray-500 h-12"></div>
        <div className="w-1/4 bg-gray-400 h-12"></div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/5 bg-gray-500 h-12"></div>
        <div className="w-1/5 bg-gray-400 h-12"></div>
        <div className="w-1/5 bg-gray-500 h-12"></div>
        <div className="w-1/5 bg-gray-400 h-12"></div>
        <div className="w-1/5 bg-gray-500 h-12"></div>
      </div>

      <div className="flex">
        <div className="w-1/6 bg-gray-400 h-12"></div>
        <div className="w-5/6 bg-gray-500 h-12"></div>
      </div> */}

  </div>
}
