import React from "react"

export default function Section8() {
  return (
    <div className="w-full p-10 lg:py-20 lg:pl-0">
    <div className="w-full py-20 bg-white">
      <div className="lg:flex w-full">
        <div className="w-full lg:w-1/2">
            <img 
                className="w-full lg:w-10/12"
                src={require("../../../assets/images/img-community.png")}
                alt=""
                width='90%'
            />
        </div>
        <div className="w-full lg:w-1/2 mt-20 lg:mt-0">
            <div className="w-3/4 p-0">
                <p className="text-3xl text-left text-black font-bold text-aileron-bold">
                Build Your Online Community as you “Share-&-Go”!
                </p>
                <div className="divider bg-accent my-10"></div>
                <p className="text-xl text-left leading-loose text-grey-800">
                  Ciergio’s intuitive platform easily improves existing processes to digitally transform businesses and organizations
                </p>
                <p className="text-xl mt-5 text-left leading-loose text-grey-800">
                  Enjoy:
                </p>
                <div class="flex text-gray-700 text-left pt-5">
                    <div class="rounded-full h-35 w-35 flex items-center justify-center bg-accent text-white mr-10"><img 
                        className=""
                        src={require("../../../assets/images/icn-check.png")}
                        alt=""
                        width='90%'
                      />
                    </div>
                    <div className="w-5/6 self-center text-left text-gray-800 text-xl">
                      Bulletin & Content Management System
                    </div>
                </div>
                <div class="flex text-gray-700 text-left pt-5">
                    <div class="rounded-full h-35 w-35 flex items-center justify-center bg-accent text-white mr-10"><img 
                        className=""
                        src={require("../../../assets/images/icn-check.png")}
                        alt=""
                        width='90%'
                      />
                    </div>
                    <div className="w-5/6 self-center text-left text-gray-800 text-xl">
                      An Organized & Comprehensive Suite of Features
                    </div>
                </div>
                <div class="flex text-gray-700 text-left pt-5">
                    <div class="rounded-full h-35 w-35 flex items-center justify-center bg-accent text-white mr-10"><img 
                        className=""
                        src={require("../../../assets/images/icn-check.png")}
                        alt=""
                        width='90%'
                      />
                    </div>
                    <div className="w-5/6 self-center text-left text-gray-800 text-xl">
                      Deeper insights into your community
                    </div>
                </div>
                <div class="flex text-gray-700 text-left pt-5">
                    <div class="rounded-full h-35 w-35 flex items-center justify-center bg-accent text-white mr-10"><img 
                        className=""
                        src={require("../../../assets/images/icn-check.png")}
                        alt=""
                        width='90%'
                      />
                    </div>
                    <div className="w-5/6 self-center text-left text-gray-800 text-xl">
                      More digital Contact-less Transactions
                    </div>
                </div>
            </div>
        </div>
        
      </div>
    </div>
    </div>
  )
}